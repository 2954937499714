<template>
  <div class="list-label">
    <labelc v-for="(label, index) in labelsProcessed" :key="'l' + index" :type="label.type" :text="label.text" :iconLeft="label.iconLeft" :iconRight="label.iconRight" :styleText="label.styleText" @click="ClickLabel($event, label)"
            v-bind:class="{'option-disabled': label.disabled}" v-bind:style="{'padding-left' : !label.iconLeft ? '10px' : ''}" :components="label.components" :tooltip="label.tooltip"/>
  </div>
</template>

<script>
    export default {
        name: "listlabel",
        data(){
            return {
                labels: [],
                remainAfterSelect: false,
            }
        },
        watch: {
            state: {
                immediate: true,
                handler(newVal, oldVal) {
                    for (let x in newVal) this[x] = newVal[x];
                }
            }
        },
        computed: {
            labelsProcessed(){
                let labels = [];
                this.labels.forEach(label => {
                    if(!label.hasOwnProperty('show') || label.show) {
                        let temp = {};
                        for (let x in label) {
                            temp[x] = label[x];
                        }
                        labels.push(temp);
                    }
                });
                labels.forEach(label => {
                    if(!label.text && label.title) label.text = label.title;
                    if(!label.text && !label.title && label.tooltip) label.text = label.tooltip;
                    if(!label.iconLeft && label.icon) {
                        if(typeof label.icon === 'string') label.iconLeft = {icon: label.icon, label: label.type && label.type.indexOf('label-center') > -1 ? label.label : null};
                        else label.iconLeft = label.icon;
                    }
                });
                return labels;
            },
        },
        props: {
            state: Object,
        },
        methods:{
            Close(){
                this.$parent.Toggle();
            },
            ClickLabel($event, label){
              if(label.disabled) return;
              if(label.click) label.click($event);
              if(!this.remainAfterSelect) this.Close();
            }
        },
    }
</script>

<style scoped>
    .list-label{
    }
    .list-label > .label{
        width: initial;
        display: flex;
        padding-right: 5px;
    }
    .option-disabled{
        opacity: .5;
        cursor: default !important;
    }
</style>
